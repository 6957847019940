import styled from 'styled-components'
import { Page as PageImpl } from '../styles'

export default function Intro() {
    return (
        <Page>
            <article>
                <header>
                    <h1 id="heading">Emergent Systems</h1>
                </header>
                <section>
                    <strong>We are an Australian-based software engineering and consultancy business.</strong>
                    <p>
                    <span>Operating since 2006, we specialise in:</span>
                    </p>
                    <ul>
                    <li><span>Full-stack web application development</span></li>
                    <li><span>Mobile applications for iOS and Android</span></li>
                    <li><span>Virtual reality consulting and high performance computer systems</span></li>
                    </ul>
                    <p className="contact"><a href="mailto:matthew.lenton@gmail.com">Contact us</a></p>
                    <footer>
                    <span>
                        <small>&copy; 2021 Emergent Systems ABN 89 964 049 832</small>
                    </span>
                    </footer>
                </section>
            </article>
        </Page>
    )
}

const Page = styled(PageImpl)`
position: absolute;
width: 100%;
pointer-events: none;

article { 
    pointer-events: all;
    margin: 0 auto;
    width: 36%;
    min-width: 390px;
    border-radius: 1em;
    overflow: hidden;
    max-width: 90%;
    border-style: solid;
    border-width: 3px;
    animation-name: zoomout;
    animation-duration: 0.25s;
    animation-timing-function: cubic;
    animation-iteration-count: 1;    
}

header { 
    background-color: rgba(44,44,44,0.8);
}

section { 
    background-color: rgba(44,44,44,0.6);
    padding: 1em;
}

@keyframes zoomout {
    0% {
        transform: scale3d(0,0,0);
    }
    100% {              
        transform: scale3d(1,1,1);
    }
}

header { 
    padding: 0em;
    border-bottom-style: solid;
    border-bottom-width: 1px;
}

header * { 
    display: inline;
}

header, 
section header, 
section footer { 
    padding: 1em;
}

section footer { 
    font-size: smaller;
    text-align: right;
} 

p.contact { 
    text-align: center;
    font-weight: bold;
    font-size: larger;
}

footer { 
    padding: 0 !important;
}

canvas { 
    z-index: 0;
}

div.article { 
    z-index: 1;
}
`