import * as THREE from 'three'
import React, { useMemo, useRef } from 'react'
import { HashRouter as Router } from 'react-router-dom'
import Intro from './pages/Intro'
import { Global } from './styles'
import { Canvas, extend, useFrame, useThree } from 'react-three-fiber'
import { vertexShader, fragmentShader } from './shaders/ParticleShader.js'
import { Stats, OrbitControls } from '@react-three/drei'

class ParticleMaterial extends THREE.ShaderMaterial { 
  constructor() { 
    super({
      depthWrite: false, 
      blending: THREE.AdditiveBlending,
      fragmentShader: fragmentShader,
      transparent: true,
      uniforms: { 
        uSize: { value: 30 }, 
        uTime: { value: 0 } 
      },
      vertexShader: vertexShader,
    })
  }
}

extend({ ParticleMaterial })

function ParticleEffect({ pointCount }) { 
  const [positions, colors, scales, randomness] = useMemo(() => {
    const parameters = {}
    parameters.count = pointCount
    parameters.size = 1
    parameters.radius = 5
    parameters.branches = 3
    parameters.spin = 1
    parameters.randomness = 1
    parameters.randomnessPower = 3
    parameters.insideColor = '#12e030'
    parameters.outsideColor = '#1a39db'

    const positions = new Float32Array(parameters.count * 3)
    const randomness = new Float32Array(parameters.count * 3)
    const colors = new Float32Array(parameters.count * 3)
    const scales = new Float32Array(parameters.count * 1)

    const insideColor = new THREE.Color(parameters.insideColor)
    const outsideColor = new THREE.Color(parameters.outsideColor)

    for(let i = 0; i < parameters.count; i++)
    {
        const i3 = i * 3

        // Position
        const radius = Math.random() * parameters.radius

        const branchAngle = (i % parameters.branches) / parameters.branches * Math.PI * 2

        const randomX = Math.pow(Math.random(), parameters.randomnessPower) * (Math.random() < 0.5 ? 1 : - 1) * parameters.randomness * radius
        const randomY = Math.pow(Math.random(), parameters.randomnessPower) * (Math.random() < 0.5 ? 1 : - 1) * parameters.randomness * radius
        const randomZ = Math.pow(Math.random(), parameters.randomnessPower) * (Math.random() < 0.5 ? 1 : - 1) * parameters.randomness * radius

        positions[i3    ] = ( Math.random() - 0.5 ) * radius
        positions[i3 + 1] = ( Math.random() - 0.5 ) * radius
        positions[i3 + 2] = ( Math.random() - 0.5 ) * radius

        positions[i3    ] = Math.sin(branchAngle) * radius
        positions[i3 + 1] = 0
        positions[i3 + 2] = Math.cos(branchAngle) * radius
    
        randomness[i3    ] = randomX
        randomness[i3 + 1] = randomY
        randomness[i3 + 2] = randomZ

        // Color
        const mixedColor = insideColor.clone()
        mixedColor.lerp(outsideColor, radius / parameters.radius)

        colors[i3    ] = mixedColor.r
        colors[i3 + 1] = mixedColor.g
        colors[i3 + 2] = mixedColor.b

        // Scale
        scales[i] = Math.random() * 10
    }

    return [
      new Float32Array(positions), 
      new Float32Array(colors),
      new Float32Array(scales),
      new Float32Array(randomness),
    ]
  }, [pointCount])

  const points = useRef()
  const material = useRef()
  const { clock } = useThree()

  useFrame(() => { 
    material.current.uniforms.uTime.value = clock.getElapsedTime()
  })

  return ( 
    <points ref={points} >
      <bufferGeometry attach="geometry" >
        <bufferAttribute
          attachObject={['attributes', 'position']}
          count={positions.length / 3}
          array={positions}
          itemSize={3} />
        <bufferAttribute 
          attachObject={['attributes', 'color']} 
          count={colors.length / 3} 
          array={colors} 
          itemSize={3} />
        <bufferAttribute 
          attachObject={['attributes', 'aRandomness']} 
          count={randomness.length / 3} 
          array={randomness} 
          itemSize={3} />
        <bufferAttribute 
          attachObject={['attributes', 'aScale']} 
          count={scales.length} 
          array={scales} 
          itemSize={1} />
      </bufferGeometry>
      <particleMaterial ref={material} vertexColors={true} />
    </points>
  )
}

export default function () {
  return (
    <Router>
      <Global />
      <Canvas colorManagement shadowMap pixelRatio={window.devicePixelRatio} camera={{ position: [0, 0, 5] }}>
        <ParticleEffect pointCount={1000} />
        <OrbitControls />
      </Canvas>
      <Intro />
    </Router>
  )
}
